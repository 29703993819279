import React from 'react';
import Layout from '@utilities/Layout'
import Header from '@molecules/Header'
import Footer from '@molecules/Footer'
import CV from '@organisms/CV'
import './App.scss';

function App() {
  return (
    <Layout>
      <Header />
      <CV />
      <Footer />
    </Layout>
  );
}

export default App;
