import React, { PureComponent } from 'react'
import Button from '@atoms/Button'
import styles from './Filters.scss'

class Filters extends PureComponent {
  render() {
    return (
      <div className={styles['filters']}>
        <h4>Filter by:</h4>
        <Button
          onClick={() => {this.props.setFilter('all')}}
          active={this.props.activeFilter === 'all'}
        >
          All
        </Button>
        <Button
          onClick={() => {this.props.setFilter('life-event')}}
          active={this.props.activeFilter === 'life-event'}
          appearance='life-event'
        >
          Life Events
        </Button>
        <Button
          onClick={() => {this.props.setFilter('education')}}
          active={this.props.activeFilter === 'education'}
          appearance='education'
        >
          Education
        </Button>
        <Button
          onClick={() => {this.props.setFilter('career')}}
          active={this.props.activeFilter === 'career'}
          appearance='career'
        >
          Career
        </Button>
      </div>
    )
  }
}

export default Filters
