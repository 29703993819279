import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import styles from './Header.scss'

class Header extends PureComponent {

  static propTypes = {
    children: PropTypes.node
  }

	render() {
		return (
      <section className={styles['site-header']}>
        <img src='/images/memoji.png' alt='Ben Hudson' width='200' height='200' />
        <h1>Ben Hudson</h1>
        <h2>Friend first, front-end developer second. Probably entertainer third.</h2>
        <p>Primary skills include - but are not limited to - always using the correct gif response on Slack, quoting The Simpsons (but only seasons 1-10, obvs) and regularly trying to leave work early to play golf. Form an orderly queue.</p>
      </section>
		)
	}
}

export default Header
