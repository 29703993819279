import React, { PureComponent } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types'
import styles from './Footer.scss'

class Footer extends PureComponent {

  static propTypes = {
    children: PropTypes.node
  }

	render() {
		return (
      <footer className={styles['site-footer']}>
        <div className={styles['footer-links']}>
          <a href='http://twitter.com/BennyHudson'><FontAwesomeIcon icon={['fab', 'twitter']} /></a>
          <a href='http://instagram.com/BennyHudson'><FontAwesomeIcon icon={['fab', 'instagram']} /></a>
          <a href='https://www.linkedin.com/in/ben-hudson-8a978529/'><FontAwesomeIcon icon={['fab', 'linkedin']} /></a>
          <a href='http://github.com/BennyHudson'><FontAwesomeIcon icon={['fab', 'github']} /></a>
          <a href='https://bitbucket.org/BennyHudson/'><FontAwesomeIcon icon={['fab', 'bitbucket']} /></a>
        </div>
        <span className={styles.wedo}><a href='http://wedo.digital'>wedo.digital</a></span>
      </footer>
		)
	}
}

export default Footer
