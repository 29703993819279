import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import styles from './Layout.scss'

class Layout extends PureComponent {

  static propTypes = {
    children: PropTypes.node
  }

	render() {
		return (
      <section className={styles['page-layout']}>
        {this.props.children}
      </section>
		)
	}
}

export default Layout
