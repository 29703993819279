import React, { PureComponent, Fragment } from 'react'
import Flip from 'react-reveal/Flip';
import PropTypes from 'prop-types'
import Filters from '@molecules/Filters'
import Card from '@molecules/Card'
import generateSlug from '@helpers/generateSlug'
import cv from '@data/cv'

class CV extends PureComponent {
  static propTypes = {
    children: PropTypes.node
  }

  state = {
    activeFilter:'all',
    cv: cv,
  }

  setFilter = (filter) => {
    this.setState({activeFilter: filter})
  }

	render() {
    const filteredCards = this.state.cv.filter((card) => {
      if (this.state.activeFilter === 'all') return true
      return generateSlug(card.type) === this.state.activeFilter
    })

		return (
      <Fragment>
        <Filters activeFilter={this.state.activeFilter} setFilter={this.setFilter} />
        <div>
          {
            filteredCards.map((card) => {
              return (
                <Flip top>
                  <Card
                    type={card.type}
                    title={card.title}
                    location={card.location}
                    time={card.time}
                  />
                </Flip>
              )
            })
          }
        </div>
      </Fragment>
		)
	}
}

export default CV
