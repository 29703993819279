import React, { PureComponent } from 'react'
import styles from './Button.scss'

class Button extends PureComponent {

  handleClick (event) {
    event.preventDefault()
    this.props.onClick.apply(this, [event])
  }

  render () {

    const buttonClasses = [
      styles['button'],
      styles[`button--appearance-${this.props.appearance}`],
      this.props.active && styles['active']
    ].join(' ')

    return (
      <button
        className={buttonClasses}
        onClick={(event) => this.handleClick(event)}
      >
        {this.props.children}
      </button>
    )
  }
}

export default Button
