import React, { PureComponent } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames'
import PropTypes from 'prop-types'
import styles from './Card.scss'

class Card extends PureComponent {

  static propTypes = {
    children: PropTypes.node
  }

	render() {
    const cardClasses = classnames(
      styles.card,
      styles[`card--${this.props.type}`]
    )

    const year = this.props.time.split(' ')[1]

		return (
      <div className={cardClasses}>
        <aside className={styles['card__icon']}>
          {this.props.type === 'life-event' && <FontAwesomeIcon icon={['fal', 'star']} size='2x' />}
          {this.props.type === 'education' && <FontAwesomeIcon icon={['fal', 'graduation-cap']} size='2x' />}
          {this.props.type === 'career' && <FontAwesomeIcon icon={['fal', 'user-tie']} size='2x' />}
        </aside>
        <aside className={styles['card__content']}>
          <h4>{this.props.time}</h4>
          <h3>{this.props.title}</h3>
          <p>{this.props.location}</p>
        </aside>
        <span className={styles.year}>{year}</span>
      </div>
		)
	}
}

export default Card
